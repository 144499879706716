import { Listbox } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { Form, Formik } from "formik";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { mutate } from "swr";
import { axiosInstance } from "../../services/AxiosInstance";
import { RegisterNewEmailDto, UserPreferencesClient } from "../../swagger";
import { Link } from "react-router-dom";
import getUserInfo from "../../swr/getUserInfo";
import * as React from "react";
import toast, { Toaster } from "react-hot-toast";

type propType ={
  cancelAction:()=> void;
}

const NewEmailForm = (props: propType) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { user, isLoadingUserApi, isErrorUserApi } = getUserInfo();
  const [showPassword, setShowPassword] = useState(false);

  React.useEffect(() => {
    if (!user?.hasPassword) {
      setDisabled(true);
    }
  }, []);

  const checkValidation = (values) => {
    let error = {} as any;
    if (!values.password) {
      error.password = "Password is required!";
    }
    if (!values.email) {
      error.email = "Email is required!";
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
    ) {
      error.email = "Email is not valid!";
    }
    return error;
  };

  const onSubmit = async (values) => {
    console.log("values", values);
    setLoading(true);
    const client = new UserPreferencesClient(undefined, axiosInstance);
    try {
      const data = {
        password: values.password,
        email: values.email,
      } as RegisterNewEmailDto;

      var res = await client.addNewEmail(data);

      if (res?.succeeded) {
        toast.success(t("identity.profile.success"));
        setLoading(false);
        props.cancelAction();
        mutate("userPreferences.getAllEmails");
        // TokenStorage.storeToken(res.data. as string);
        // TokenStorage.storeRefreshToken(res.refresh_token as string);
        // window.location.replace("/");
      } else {
        setLoading(false);
        toast.error(_.first(res.errors) as any);
        // addToast(res.error, { appearance: "error" });
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        t(
          "identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"
        )
      );
      // addToast("Server Error Occured", { appearance: "error" });
    }
    setLoading(false);
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={true}></Toaster>
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <div className="bg-white pt-6 shadow sm:overflow-hidden sm:rounded-md">
          <div className="px-4 sm:px-6">
            <h2 className="text-lg font-medium leading-6 text-gray-900">
              {t("identity.profile.EmailAddresses")}
            </h2>
          </div>
          <Formik
            onSubmit={onSubmit}
            initialValues={{
              email: "",
              password: "",
            }}
            validate={checkValidation}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mt-6 flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                      <div className="overflow-hidden border-t border-gray-200">
                        <div className="p-6 grid grid-cols-1 gap-y-6">
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            {t("identity.emailAddresses.AddANewEmail")}
                          </h3>
                          <div>
                            <label className="flex text-sm font-medium text-gray-700">
                              {t(
                                "identity.emailAddresses.EnterNewEmailAddress"
                              )}
                            </label>
                            <div className="flex w-3/5 items-center ">
                              <div
                                className={`grow relative mt-1 rounded-md shadow-sm  ${
                                  disabled ? "cursor-not-allowed" : ""
                                }`}
                              >
                                <input
                                  type="text"
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  disabled={disabled}
                                  className={classNames(
                                    {
                                      "border border-red-300 placeholder-red-300 focus:ring-red-500  text-red-900  focus:border-red-500":
                                        errors.email,
                                    },
                                    "p-2 block w-full rounded-md border border-gray-300  pr-10  focus:outline-none  sm:text-sm"
                                  )}
                                />
                                {errors.email ? (
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div className="flex text-sm ml-1 mt-1 text-gray-400">
                              {disabled ? (
                                <div>
                                  {t("identity.emailAddresses.please")}{" "}
                                  <Link to="/account/ForgetPassword">
                                    <a className="text-blue-700 underline cursor-pointer">
                                      {t(
                                        "identity.emailAddresses.setAPassword"
                                      )}
                                    </a>
                                  </Link>{" "}
                                  {t("identity.emailAddresses.first")}
                                </div>
                              ) : (
                                t(
                                  "identity.emailAddresses.AConfirmationWillBeSentToThisAccountClickOnTheConfirmationLinkToVerifyAndAddThisEmail"
                                )
                              )}
                            </div>
                            {errors.email ? (
                              <p className="flex text-sm ml-1 text-red-600">
                                {t(errors.email.toString())}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div>
                            <label className="flex text-sm font-medium text-gray-700">
                              {t(
                                "identity.emailAddresses.EnterYourVoxdashPassword"
                              )}
                            </label>
                            <div className="w-3/5 flex items-center ">
                              <div
                                className={`grow relative mt-1 rounded-md shadow-sm  ${
                                  disabled ? "cursor-not-allowed" : ""
                                }`}
                              >
                                <div className="absolute cursor-pointer inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                                  <svg
                                    fill="none"
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                    className={classNames("h-3 text-gray-700", {
                                      hidden: !showPassword,
                                      block: showPassword,
                                    })}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                                    ></path>
                                  </svg>

                                  <svg
                                    fill="none"
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                    className={classNames("h-3 text-gray-700", {
                                      hidden: showPassword,
                                      block: !showPassword,
                                    })}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 640 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                                    ></path>
                                  </svg>
                                </div>
                                <input
                                  type={showPassword ? "text" : "password"}
                                  name="password"
                                  value={values.password}
                                  onChange={handleChange}
                                  disabled={disabled}
                                  className={classNames(
                                    {
                                      "border  border-red-300 placeholder-red-300 focus:ring-red-500  text-red-900  focus:border-red-500":
                                        errors.password,
                                    },
                                    "p-2 block w-full rounded-md border border-gray-300  pr-10  focus:outline-none  sm:text-sm"
                                  )}
                                />
                                {errors.password ? (
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            {errors.password ? (
                              <p className="flex text-sm ml-1 text-red-600">
                                {t(errors.password.toString())}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <div className="flex justify-end px-4 sm:px-0">
                    <button
                      onClick={props.cancelAction}
                      type="button"
                      className="rounded-md w-20 bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      {t("identity.basicProfileInfo.Back")}
                    </button>
                    <button
                      disabled={disabled}
                      type="submit"
                      className={`ml-3 w-20 inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500  ${
                        disabled ? "cursor-not-allowed" : "cursor-pointer"
                      }`}
                    >
                      {loading ? (
                        <>
                          {" "}
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              className="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Loading...</span>
                          </div>
                        </>
                      ) : (
                        <>{t("identity.basicProfileInfo.Submit")}</>
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default NewEmailForm;
