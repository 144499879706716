import * as React from "react";
import {
  DataGrid,
  GridFilterModel,
  GridSortModel,
  GridSortItem,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
  GridRowId,
  GridCellParams,
} from "@mui/x-data-grid";

import { Theme, styled, createTheme } from "@mui/material/styles";
import _, { floor } from "lodash";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { DtParameterInput } from "../../swagger";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

type propType = {
  className?: string;
  dtParameter: any;
  setDtParameter: (dtParameter: DtParameterInput) => void;
  pageSizes: number[];
  totalCount: number;
  rows: any[];
  columns: any[];
  selectable: boolean;
  selectedList?: GridRowId[];
  setSelectedList?: (selected: GridRowId[]) => void;
  loading: boolean;
  removeToolbar: boolean;
  clientSort: boolean;
  clientPagination: boolean;
  clientFilter: boolean;
  onCellClick?: (selected: any) => void;
  getRowClassName?: (s: any) => string;
  dontShowPagination?: boolean;
  onRowDoubleClick?: (selected: any) => void;
  onRowClick?: (seleted: any) => void;
  isRowSelectable?: (data: any) => boolean;
};

export default function TableComponent(props: propType) {
  const [sort, setSort] = React.useState<GridSortItem>();
  const [pageSizeSelect, setPageSizeSelect] = React.useState() as any;
  const [pageNumSelect, setPageNumSelect] = React.useState(
    props.dtParameter.current
  ) as any;
  const [totalPageNumber , setTotalPageNumber] = React.useState(floor(props.totalCount/props.dtParameter.pageSize))
  const [clickTimer, setClickTimer] = React.useState(null);

  React.useEffect(()=>{
    setTotalPageNumber(floor(props.totalCount/props.dtParameter.pageSize))
  },[props.dtParameter , props.totalCount])


  const handleRowClick = (data: any) => {
    if (props.onRowDoubleClick) {
      if (clickTimer) {
        clearTimeout(clickTimer);
        setClickTimer(null);
        handleRowDoubleClick(data);
      } else {
        const timer = setTimeout(() => {
          setClickTimer(null);
          if (props.onRowClick) {
            props.onRowClick(data);
          }
        }, 200) as any;
        setClickTimer(timer);
      }
    } else if (props.onRowClick) {
      props.onRowClick(data);
    }
  };

  const handleRowDoubleClick = (data: any) => {
    if (props.onRowDoubleClick) {
      props.onRowDoubleClick(data);
    }
  };

  const checkIsTableSelectable = () => {
    if (
      props.selectable ||
      props.onRowClick ||
      props.onRowDoubleClick ||
      props.onCellClick
    )
      return true;
    else return false;
  };

  const onPageSizeChange = (pageSize: number) => {
    console.log("onPageSizeChange", props.dtParameter);

    if (props.dtParameter.current * pageSize > props.totalCount)
      props.setDtParameter({
        ...props.dtParameter,
        current: 1,
      } as DtParameterInput);
    props.setDtParameter({
      ...props.dtParameter,
      pageSize: pageSize,
    } as DtParameterInput);
  };

  const onSortChange = React.useCallback(
    (sortModel: GridSortModel) => {
      if (sortModel == null || _.isEmpty(sortModel)) {
        setSort(undefined);
        props.setDtParameter({
          ...props.dtParameter,
          dir: undefined,
          orderBy: undefined,
        } as DtParameterInput);
      } else {
        const model = sortModel[0];
        setSort(model);
        props.setDtParameter({
          ...props.dtParameter,
          dir: model.sort,
          orderBy: model.field,
        } as DtParameterInput);
      }
    },
    [sort]
  );

  const onFilterChange = (model: GridFilterModel) => {};

  console.log("pageSizeSelect", props.dtParameter);

  function CustomPagination() {
    return !props.dontShowPagination && props.totalCount != 0 ? (
      <div className="w-full h-fit flex md:flex-row flex-col-reverse justify-center items-center space-x-6 md:gap-y-0 gap-y-7 md:py-3 py-7">
        <Pagination
          color="primary"
          variant="text"
          shape="rounded"
          page={props.dtParameter.current}
          count={Math.ceil(props.totalCount / props.dtParameter.pageSize)}
          // @ts-expect-error
          renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
          onChange={(event: React.ChangeEvent<unknown>, value: number) => {
            props.setDtParameter({
              ...props.dtParameter,
              current: value,
            } as DtParameterInput);
            console.log("setDtParameter", props.dtParameter, value);
          }}
        />

        <div className="w-fit h-fit flex flex-row  justify-center items-center md:gap-x-7 gap-x-4 md:gap-y-0 gap-y-7">
          <div
            className={
              "w-fit h-fit flex flex-row justify-center items-center gap-x-3"
            }
          >
            <p className="text-[#000000] font-[500] text-[14px]">Show:</p>
            <div className="w-[70px] h-[35px]">
              <select
                id="Show"
                name="Show"
                className="w-full bg-[#FFFFFF] px-2 pt-1 pb-[5px]  border-solid border-[1px] shadow-[0_2px_5px_rgba(60,66,87,0.12)] border-[#9AA9BF] rounded-[4px] text-[13px] flex flex-row justify-center items-center"
                defaultValue={pageSizeSelect}
                onChange={(e) => {
                  setPageSizeSelect(+e.target.value);
                  props.setDtParameter({
                    ...props.dtParameter,
                    pageSize: +e.target.value,
                  } as DtParameterInput);
                }}
              >
                {props.pageSizes.map((size) => (
                  <option key={size}>{size}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="w-fit h-fit flex flex-row justify-center items-center md:gap-x-3  gap-x-1">
            <p className="text-[#000000] font-[500] text-[14px]">Go to:</p>
            <input
              onChange={(e) => {
                setPageNumSelect(+e.target.value);
                props.setDtParameter({
                  ...props.dtParameter,
                  current: +e.target.value,
                } as DtParameterInput);
              }}
              value={pageNumSelect}
              className="w-[35px] h-[35px] text-center text-xs bg-[#FFFFFF] border-solid border-[1px] shadow-[0_2px_5px_rgba(60,66,87,0.12)] border-[#9AA9BF] rounded-[4px] numberfield"
              type="text"
            />
            <p className="text-[#828A96] font-[400] text-[14px]">
              of {Math.ceil(props.totalCount / props.dtParameter.pageSize)}
            </p>
          </div>
        </div>
      </div>
    ) : null;
  }

  function customCheckbox(theme: Theme) {
    return {
      "& .MuiCheckbox-root svg": {
        width: 16,
        height: 16,
        backgroundColor: "transparent",
        border: `1px solid ${
          theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
        }`,
        borderRadius: 2,
      },
      "& .MuiCheckbox-root svg path": {
        display: "none",
      },
      "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
        backgroundColor: "#1890ff",
        borderColor: "#1890ff",
      },
      "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
        position: "absolute",
        display: "table",
        border: "2px solid #fff",
        borderTop: 0,
        borderLeft: 0,
        transform: "rotate(45deg) translate(-50%,-50%)",
        opacity: 1,
        transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
        content: '""',
        top: "50%",
        left: "39%",
        width: 5.71428571,
        height: 9.14285714,
      },
      "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
        {
          width: 8,
          height: 8,
          backgroundColor: "#1890ff",
          transform: "none",
          top: "39%",
          border: 0,
        },
    };
  }

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    // marginLeft : "-50px" ,

    justifyContent: "start",
    border: 0,
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      // maxWidth: "500px" ,
      // overflow: "auto" ,
      borderRight: `0px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },

    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .MuiDataGrid-cell": {
      justifyContent: "start",
      fontSize: "14px",
      fontWeight: "400",
      color:
        theme.palette.mode === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.65)",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: 0,
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: "#9AA9BF",
      fontSize: "16px",
      fontWeight: "550",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer , .MuiDataGrid-columnsContainer , .MuiDataGrid-columnHeader--alignCenter , .css-1mm7gx-MuiDataGrid-root , .MuiDataGrid-columnHeaderTitleContainer":
      {
        justifyContent: "start",
      },
    "& .css-16cwii1-MuiDataGrid-root .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeaderTitleContainer":
      {
        justifyContent: "start",
      },
    "& .css-1282wpz-MuiDataGrid-root .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeaderTitleContainer":
      {
        justifyContent: "start",
      },
    "& .css-yrdy0g-MuiDataGrid-columnHeaderRow ": {
      justifyContent: "start",
    },

    "&.css-pmqbxc-MuiDataGrid-root .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeaderTitleContainer ":
      {
        justifyContent: "start",
      },

    // ...customCheckbox(theme),
  }));
  const theme = createTheme({
    palette: {
      primary: {
        main: "#f44336",
        light: "#f44336",
        contrastText: "#f44336",
        "100": "#f44336",
        "200": "#f44336",
        "300": "#f44336",
        "400": "#f44336",
        "500": "#f44336",
        "600": "#f44336",
        "700": "#f44336",
        "800": "#f44336",
        "900": "#f44336",
        "50": "#f44336",
        A100: "#f44336",
        A200: "#f44336",
        A400: "#f44336",
        A700: "#f44336",
        dark: "#f44336",
      },
      secondary: {
        main: "#f44336",
        light: "#f44336",
        contrastText: "#f44336",
        "100": "#f44336",
        "200": "#f44336",
        "300": "#f44336",
        "400": "#f44336",
        "500": "#f44336",
        "600": "#f44336",
        "700": "#f44336",
        "800": "#f44336",
        "900": "#f44336",
        "50": "#f44336",
        A100: "#f44336",
        A200: "#f44336",
        A400: "#f44336",
        A700: "#f44336",
        dark: "#f44336",
      },
    },
  });

  return (
    <div
      style={{
        height:
          (props.dontShowPagination ? 150 : 200) +
          50 * props.dtParameter?.pageSize,
      }}
    >
      {props.columns ? (
        <DataGrid
          //autoHeight
          sx={{
            justifyContent: "start",
            border: 0,
            color: "rgba(0,0,0,.85)",
            fontFamily: [
              "-apple-system",
              "BlinkMacSystemFont",
              '"Segoe UI"',
              "Roboto",
              '"Helvetica Neue"',
              "Arial",
              "sans-serif",
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ].join(","),
            WebkitFontSmoothing: "auto",
            letterSpacing: "normal",
            "& .MuiDataGrid-columnsContainer": {
              backgroundColor: "#fafafa",
            },
            "& .MuiDataGrid-iconSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
              // maxWidth: "500px" ,
              borderRight: `0px solid ${"#f0f0f0"}`,
            },
            "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
              borderBottom: `1px solid ${"#f0f0f0"}`,
            },
            "& .MuiDataGrid-cell": {
              justifyContent: "start",
              fontSize: "14px",
              fontWeight: "400",
              color: "rgba(0,0,0,.85)",
            },
            "& .MuiPaginationItem-root": {
              borderRadius: 0,
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "#9AA9BF",
              fontSize: "16px",
              fontWeight: "550",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer , .MuiDataGrid-columnsContainer , .MuiDataGrid-columnHeader--alignCenter , .css-1mm7gx-MuiDataGrid-root , .MuiDataGrid-columnHeaderTitleContainer":
              {
                justifyContent: "start",
              },
            "& .css-16cwii1-MuiDataGrid-root .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeaderTitleContainer":
              {
                justifyContent: "start",
              },
            "& .css-1282wpz-MuiDataGrid-root .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeaderTitleContainer":
              {
                justifyContent: "start",
              },
            "& .css-yrdy0g-MuiDataGrid-columnHeaderRow ": {
              justifyContent: "start",
            },

            "&.css-pmqbxc-MuiDataGrid-root .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeaderTitleContainer ":
              {
                justifyContent: "start",
              },
          }}
          onCellClick={props.onCellClick ? props.onCellClick : () => {}}
          components={
            props.removeToolbar
              ? {
                  Pagination: CustomPagination,
                }
              : {
                  Pagination: CustomPagination,
                  Toolbar: GridToolbar,
                }
          }
          // theme={theme}
          className={props.className}
          getRowClassName={props.getRowClassName}
          rows={props.rows}
          columns={props.columns}
          pagination
          checkboxSelection={props.selectable}
          pageSize={props.dtParameter.pageSize}
          onPageSizeChange={onPageSizeChange}
          rowsPerPageOptions={props.pageSizes}
          rowCount={props.totalCount}
          paginationMode={props.clientPagination ? "client" : "server"}
          filterMode={props.clientFilter ? "client" : "server"}
          page={props.dtParameter.current - 1}
          onPageChange={(page) => page <= totalPageNumber?
            props.setDtParameter({
              ...props.dtParameter,
              current: page + 1,
            } as DtParameterInput)
            :null
          }
          loading={props.loading}
          onFilterModelChange={props.clientFilter ? () => {} : onFilterChange}
          sortingMode={props.clientSort ? "client" : "server"}
          //sortModel = {props.dtParameter.sort}
          onSortModelChange={props.clientSort ? () => {} : onSortChange}
          onRowClick={(data) => handleRowClick(data)}
          onRowDoubleClick={(data) => handleRowDoubleClick(data)}
          isRowSelectable={(data) =>
            props.isRowSelectable
              ? props.isRowSelectable(data)
              : checkIsTableSelectable()
          }
          selectionModel={props.selectedList ? props.selectedList : []}
          onSelectionModelChange={(newSelection: any) => {
            if (props.setSelectedList) {
              props.setSelectedList(newSelection);
            }
          }}
        />
      ) : null}
    </div>
  );
}
